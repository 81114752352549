import { removeOrgIdCookie, removePermissionCookies } from '@/components/helpers/cookies';
import Router from 'next/router';
import { AUTH_ENABLED } from '../config';

export const logout = () => {
  if (!AUTH_ENABLED) return;
  removeOrgIdCookie();
  removePermissionCookies();
  Router.replace('/api/auth/logout');
};
