import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { LOG_LEVEL } from '../Providers/config';
import { logger } from '../Analytics';

export const LaunchDarklyLogLevelSetter = () => {
  const flags = useFlags();

  useEffect(() => {
    logger.level = flags.logLevel || LOG_LEVEL;
  }, [flags.logLevel]);

  return <></>;
};
