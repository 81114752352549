import { useDialog } from '@/components/Dialog';
import { BLUETOOTH_ENABLED } from '@/components/Providers/config';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { BTConnectionState } from './BTConnectionState';
import { BTGestureNeededDialog } from './BTGestureNeededDialog';
import { BTManager, BTManagerEvent } from './BTManager';
import { BTReconnectDialog } from './BTReconnectDialog';
import { logger } from '../Analytics/logger';

// Test procedure:
//
// - Navigate to the loading route: we should be prompted to connect
// - Navigate to the feeding route: we should still be connected
// - Turn off the scale: we should be prompted to reconnect
// - Navigate to load summary route: we should not be disconnected
// - Navigate to inventory route: we should be disconnecting
// - Navigate back to loading route: we should be prompted to reconnect
//
// Also test with BLUETOOTH_ENABLED = 0

const btEnabledRouteRegex = /\/dashboard\/feeding\/loads-feeds\/\d+\/(loading|feeding)/;
const manager = BTManager.getInstance();

const DELAY_ZERO_OUT = 3000;

export const BTWeightContext = createContext<{
  connect: () => void;
  disconnect: () => void;
  scaleName: string | null;
  scaleLocked: boolean;
  setScaleLocked: (lock: boolean) => void;
  setWeight: (weight: number | null) => void;
  zeroOutScale: () => void;
  isConnected: boolean;
  connectionState: BTConnectionState;
  weight: number | null;
}>({
  connect: () => {},
  disconnect: () => {},
  scaleName: null,
  scaleLocked: false,
  setScaleLocked: () => {},
  setWeight: () => {},
  zeroOutScale: () => {},
  isConnected: false,
  connectionState: BTConnectionState.DISCONNECTED,
  weight: null,
});

export const BTWeightProvider = ({ children }: { children: ReactNode }) => {
  const [weight, setWeight] = useState<number | null>(null);
  const [connectionState, setConnectionState] = useState<BTConnectionState>(BTConnectionState.DISCONNECTED);
  const [scaleName, setScaleName] = useState<string | null>(null);
  const [scaleLocked, setScaleLocked] = useState<boolean>(false);
  const {
    open: gestureDialogOpen,
    handleClose: gestureDialogHandleClose,
    handleOpen: gestureDialogHandleOpen,
  } = useDialog();
  const {
    open: reconnectDialogOpen,
    handleClose: reconnectDialogHandleClose,
    handleOpen: reconnectDialogHandleOpen,
  } = useDialog();
  const router = useRouter();
  const urlRequiresScale = useMemo(() => router.asPath.match(btEnabledRouteRegex), [router.asPath]);

  const connect = useCallback(() => {
    manager.requestConnection();
  }, []);

  const disconnect = useCallback(() => {
    manager.disconnect();
  }, []);

  const onConnectionFailed = useCallback(() => {
    if (urlRequiresScale) {
      reconnectDialogHandleOpen();
    }
  }, [reconnectDialogHandleOpen, urlRequiresScale]);

  const onDisconnectedByDevice = useCallback(() => {
    if (urlRequiresScale) {
      manager.requestConnection();
    }
  }, [urlRequiresScale]);

  useEffect(() => {
    if (!BLUETOOTH_ENABLED) return;
    manager.on(BTManagerEvent.NEED_GESTURE, () => gestureDialogHandleOpen());
    manager.on(BTManagerEvent.CONNECTION_FAILED, () => onConnectionFailed());
    manager.on(BTManagerEvent.DISCONNECTED_BY_DEVICE, () => onDisconnectedByDevice());
    manager.on(BTManagerEvent.WEIGHT_CHANGED, (weight: number | null) => setWeight(weight));
    manager.on(BTManagerEvent.STATE_CHANGE, () => {
      setConnectionState(manager.getState());
      setScaleName(manager.deviceName);
    });
    return () => {
      manager.removeAllListeners();
    };
  }, [
    gestureDialogHandleOpen,
    onConnectionFailed,
    onDisconnectedByDevice,
    reconnectDialogHandleOpen,
    router.asPath,
    setWeight,
  ]);

  const zeroOutScale = useCallback(async () => {
    if (!BLUETOOTH_ENABLED) {
      setWeight(0);
      return;
    }
    await manager.zeroOut();
    await new Promise((resolve) => setTimeout(resolve, DELAY_ZERO_OUT)); // prevent the weight from spiking
  }, []);

  const onGestureDialogHandleClose = () => {
    gestureDialogHandleClose();
    manager.requestConnection(); // fire and forget
  };

  const onReconnectDialogHandleConfirm = () => {
    manager.requestConnection(); // fire and forget
    reconnectDialogHandleClose();
  };

  const onReconnectDialogHandleCancel = () => {
    reconnectDialogHandleClose();
  };

  useEffect(() => {
    if (!BLUETOOTH_ENABLED) return;
    // - On URL where we actively need bluetooth, require a connection
    // - On URL where we don't actively need bluetooth but probably will soon, don't disconnect if connected, but don't
    //   require a connection either.
    // - On other URLs, disconnect if connected
    if (urlRequiresScale) {
      logger.debug(`BT Provider: Connecting because URL is ${router.asPath}`);
      manager.requestConnection(); // fire and forget
    } else if (!router.asPath.startsWith('/dashboard/feeding')) {
      logger.debug(`BT Provider: Disconnecting because URL is ${router.asPath}`);
      manager.disconnect();
    }
  }, [router.asPath, urlRequiresScale]);

  const contextValue = useMemo(
    () => ({
      connect,
      disconnect,
      scaleLocked,
      weight,
      setScaleLocked,
      setWeight,
      zeroOutScale: zeroOutScale,
      scaleName,
      isConnected: weight !== null,
      connectionState, // Avoid. Prefer using isConnected.
    }),
    [connect, disconnect, scaleLocked, weight, connectionState, zeroOutScale, scaleName]
  );

  return (
    <BTWeightContext.Provider value={contextValue}>
      <BTGestureNeededDialog open={gestureDialogOpen} onClose={onGestureDialogHandleClose} />
      <BTReconnectDialog
        open={reconnectDialogOpen}
        onConfirm={onReconnectDialogHandleConfirm}
        onCancel={onReconnectDialogHandleCancel}
      />
      {children}
    </BTWeightContext.Provider>
  );
};
