import { UserAnalytics } from '@/components/Analytics';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { ReactNode } from 'react';
import { NoAuthPermissions, Permissions } from '../../hooks/Permissions';
import { AUTH_ENABLED } from '../config';
import { LoginRequiredProvider } from './LoginRequiredProvider';
import { OrgIdProvider } from './OrgIdProvider';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  if (!AUTH_ENABLED) {
    return (
      <>
        <NoAuthPermissions />
        <OrgIdProvider>{children}</OrgIdProvider>
      </>
    );
  }

  return (
    <UserProvider>
      <Permissions />
      <LoginRequiredProvider>
        <OrgIdProvider>
          {children}
          <UserAnalytics />
        </OrgIdProvider>
      </LoginRequiredProvider>
    </UserProvider>
  );
};
