import '@/components/Analytics/logWindowError';
import { theme } from '@/components/AppTheme';
import { BTWeightProvider } from '@/components/Bluetooth';
import { NavigationProvider } from '@/components/DashboardLayout/context';
import { DevToolsProvider } from '@/components/DevTools/DevToolsProvider';
import { EditProvider } from '@/components/EditContext';
import { AppErrorBoundary } from '@/components/ErrorBoundary';
import { LaunchDarklyProvider } from '@/components/LaunchDarkly';
import '@/components/MUILicense';
import { OrgSettingsProvider } from '@/components/OrgSettings/OrgSettings';
import '@/components/globals';
import { SetSuperAdminPermissions } from '@/components/hooks/SetSuperAdminPermissions';
import { SnackbarProvider } from '@/components/Providers/SnackbarProvider';
import { UrqlProvider } from '@/components/Providers/UrqlProvider';
import { AuthProvider } from '@/components/Providers/auth/AuthProvider';
import '@/styles/globals.css';
import ModalManager from '@ebay/nice-modal-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { LaunchDarklyLogLevelSetter } from '@/components/LaunchDarkly/LaunchDarklyLogLevelSetter';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        {process.env.NODE_ENV !== 'production' && <link rel="icon" type="image/png" href="/favicon-32x32-black.png" />}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <title>Redbook</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover" />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppErrorBoundary>
          <AuthProvider>
            <LaunchDarklyProvider>
              <LaunchDarklyLogLevelSetter />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SnackbarProvider>
                  <UrqlProvider>
                    <OrgSettingsProvider>
                      <ModalManager.Provider>
                        <SetSuperAdminPermissions />
                        <NavigationProvider>
                          <BTWeightProvider>
                            <DevToolsProvider>
                              <EditProvider>
                                <Component {...pageProps} />
                              </EditProvider>
                            </DevToolsProvider>
                          </BTWeightProvider>
                        </NavigationProvider>
                      </ModalManager.Provider>
                    </OrgSettingsProvider>
                  </UrqlProvider>
                </SnackbarProvider>
              </LocalizationProvider>
            </LaunchDarklyProvider>
          </AuthProvider>
        </AppErrorBoundary>
      </ThemeProvider>
    </>
  );
}
