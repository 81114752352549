import EventEmitter from 'events';
import { BTConnectionState } from './BTConnectionState';
import { characteristicUuids, getPointWeight, namePrefix, serviceUuid, zeroOut } from './models/point';
import { logger } from '../Analytics';

class StateAssertionError extends Error {
  constructor() {
    super('Should abort connection');
  }
}

export enum BTManagerEvent {
  STATE_CHANGE = 'stateChange',
  NEED_GESTURE = 'needGesture',
  CONNECTION_FAILED = 'connectionFailed',
  DISCONNECTED_BY_DEVICE = 'disconnectedByDevice',
  WEIGHT_CHANGED = 'weightChanged',
}

export class BTManager extends EventEmitter {
  private static instance: BTManager;
  private device: BluetoothDevice | null = null;
  private weightChar: BluetoothRemoteGATTCharacteristic | null = null;
  private zeroChar: BluetoothRemoteGATTCharacteristic | null = null;
  private desiredState: BTConnectionState = BTConnectionState.DISCONNECTED;
  private state: BTConnectionState = BTConnectionState.DISCONNECTED;

  get deviceName() {
    if (this.state === BTConnectionState.CONNECTED) {
      return this.device?.name ?? null;
    }
    return null;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new BTManager();
    }
    return this.instance;
  }

  public requestConnection = async () => {
    this.desiredState = BTConnectionState.CONNECTED;
    switch (this.state) {
      case BTConnectionState.DISCONNECTED:
        this.connect(); // fire and forget
        break;
      case BTConnectionState.CONNECTING:
        logger.debug('Ignored request to connect: already connecting');
        break;
      case BTConnectionState.CONNECTED:
        logger.debug('Ignored request to connect: already connected');
        break;
    }
  };

  public zeroOut = async () => {
    if (this.state != BTConnectionState.CONNECTED) {
      logger.debug(`Ignored request to zero out: currently ${this.state}`);
      return;
    }
    // send the "zero" instruction to the scale, telling it to zero out the weight
    try {
      zeroOut(this.zeroChar!);
    } catch (e: any) {
      logger.error('Error zeroing out', e);
    }
  };

  private checkDesiredState = (state: BTConnectionState) => {
    if (this.desiredState != state) {
      throw new StateAssertionError();
    }
  };

  private connect = async () => {
    this.setState(BTConnectionState.CONNECTING);

    try {
      logger.debug('Acquiring device');
      this.device = await navigator.bluetooth.requestDevice({
        filters: [{ namePrefix }],
        optionalServices: [serviceUuid],
      });
      this.checkDesiredState(BTConnectionState.CONNECTED);
      if (!this.device?.gatt) {
        throw new Error('Device has no GATT server');
      }
      this.device.addEventListener('gattserverdisconnected', this.onDisconnectedEventFromDevice);
      logger.debug(`Acquired device ${this.device.name}`);

      await this.device.gatt.connect();
      this.checkDesiredState(BTConnectionState.CONNECTED);
      logger.debug(`Connected to device. Connection status: ${this.device.gatt.connected}`);

      const svc = await this.device.gatt.getPrimaryService(serviceUuid);
      this.checkDesiredState(BTConnectionState.CONNECTED);
      logger.debug(`Acquired service: ${svc.uuid}`);

      this.weightChar = await svc.getCharacteristic(characteristicUuids.weight);
      this.checkDesiredState(BTConnectionState.CONNECTED);
      logger.debug(`Got weight characteristic ${this.weightChar.uuid}`);

      this.zeroChar = await svc.getCharacteristic(characteristicUuids.zero);
      this.checkDesiredState(BTConnectionState.CONNECTED);
      logger.debug(`Got zero characteristic ${this.zeroChar.uuid}`);

      await this.weightChar.startNotifications();
      this.checkDesiredState(BTConnectionState.CONNECTED);
      logger.debug(`Started characteristic notifications`);

      this.weightChar.addEventListener('characteristicvaluechanged', this.onCharacteristicEvent);
      this.checkDesiredState(BTConnectionState.CONNECTED);
      logger.debug('Added event listeners');

      const weightVal = await this.weightChar.readValue();
      const weight = getPointWeight(weightVal, logger.debug);
      logger.debug('Read initial weight');

      this.setState(BTConnectionState.CONNECTED);
      this.emitWeight(weight);
    } catch (e: any) {
      this.setState(BTConnectionState.DISCONNECTED);

      if (e?.message?.includes('gesture')) {
        // Handle the following error (Desktop Chrome)
        // > ERROR: Failed to execute 'requestDevice' on 'Bluetooth': Must be handling a user gesture to show a permission request.
        logger.debug('Need gesture');
        this.emit(BTManagerEvent.NEED_GESTURE);
      } else if (e instanceof StateAssertionError) {
        logger.debug('Aborted connection attempt');
      } else if (typeof e == 'string' && e.includes('The connection has timed out unexpectedly')) {
        // This happens when iOS mistakenly sees the device as connectable when it's not connectable
        // anymore (out of range). In that case, it's OK to try to reconnect right away.
        logger.debug('Re-connecting due to timeout while connecting');
        this.connect();
      } else {
        logger.error('Failed to acquire device', e);
        this.emit(BTManagerEvent.CONNECTION_FAILED);
      }
    }
  };

  public disconnect = () => {
    logger.debug('Disconnecting...');
    this.desiredState = BTConnectionState.DISCONNECTED;
    try {
      if (this.weightChar) {
        this.weightChar.removeEventListener('characteristicvaluechanged', this.onCharacteristicEvent);
      }
      if (this.device?.gatt) {
        this.device.removeEventListener('gattserverdisconnected', this.onDisconnectedEventFromDevice);
        try {
          this.device.gatt.disconnect();
        } catch (e: any) {
          /* ignore */
        }
      }
      this.device = null;
      this.zeroChar = null;
      this.weightChar = null;

      this.setState(BTConnectionState.DISCONNECTED);
      this.emitWeight(null);
      logger.debug('Disconnected.');
    } catch (e: any) {
      logger.error('Failed to disconnect', e);
    }
  };

  /**
   * This typically happens when the scale goes out of range (or is turned off).
   */
  private onDisconnectedEventFromDevice = () => {
    if (this.state != BTConnectionState.CONNECTED) {
      logger.debug('Received "disconnected" event from device. Ignoring with reason: Not connected.');
      return;
    }
    if (this.desiredState == BTConnectionState.DISCONNECTED) {
      logger.debug('Received "disconnected" event from device. Ignoring with reason: Already trying to disconnect.');
      return;
    }
    logger.debug('Received "disconnected" event from device.');
    this.disconnect();
    this.emit(BTManagerEvent.DISCONNECTED_BY_DEVICE);
  };

  private onCharacteristicEvent = (event: any) => {
    try {
      this.checkDesiredState(BTConnectionState.CONNECTED);
      const newWeight = getPointWeight(event.target.value as DataView, logger.debug);
      this.emitWeight(newWeight);
    } catch (e: any) {
      if (e instanceof StateAssertionError) {
        logger.debug('Aborted weight parsing attempt');
      } else {
        logger.error('Failed to parse weight', e);
      }
    }
  };

  private emitWeight = (weight: number | null) => {
    logger.debug(`Emit weight: ${weight}`);
    this.emit(BTManagerEvent.WEIGHT_CHANGED, weight);
  };

  public getState = () => this.state;

  private setState = (state: BTConnectionState) => {
    logger.debug(`Set state: ${state}`);
    this.state = state;
    this.emit(BTManagerEvent.STATE_CHANGE, state);
  };
}
