import { orgIdCookieName, permissionsCookieName, permissionsHashCookieName } from '@/common/config';
import { UserPermissions } from '@/common/types/userPermissions';
import { AUTH_ENABLED } from '@/components/Providers/config';
import Cookies from 'js-cookie';

export const removePermissionCookies = () => {
  if (AUTH_ENABLED) {
    Cookies.remove(permissionsCookieName, { path: '/' });
    Cookies.remove(permissionsHashCookieName, { path: '/' });
  }
};

export const setPermissionCookies = (permissions: UserPermissions, permissionsHash: string) => {
  Cookies.set(permissionsCookieName, JSON.stringify(permissions), { path: '/' });
  Cookies.set(permissionsHashCookieName, permissionsHash, { path: '/' });
  // TODO: check the cookies have been set correctly and throw if not?
  // Background: Cookies.set [silently fails](https://github.com/js-cookie/js-cookie/issues/470) if the cookie value
  // is too large
};

export const setOrgIdCookie = (orgId: number) => {
  Cookies.set(orgIdCookieName, `${orgId}`, { path: '/' });
};

export const removeOrgIdCookie = () => {
  Cookies.remove(orgIdCookieName, { path: '/' });
};
